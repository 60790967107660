<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="uid" width="80" label="用户编号"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="avatar" width="80" label="头像">
          <template v-slot="s">
            <img :src="s.row.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: 3px" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="department.title" label="部门"></el-table-column>
        <el-table-column prop="position.title" label="职位"></el-table-column>
        <el-table-column prop="pass_card_num" label="递名片次数"></el-table-column>
        <el-table-column prop="service_user_num" label="关联用户数量"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="$router.push({
            name:'card-staff-edit',
            })" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="$router.push({
            name:'card-staff-edit',
            query:{id:s.row.id}
            })" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "manage",
  components:{

  },
  data() {
    return {
      list:[],
      page:1,
      total:0,
      size:0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(item){
      this.$u.api.card.staffDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.card.staffSearch({
        page:this.page,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>